import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news4pic1 from '../assets/images/news4-2.jpg'
import news4pic2 from '../assets/images/news4-3.jpg'
import news4pic3 from '../assets/images/news4-4.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News4 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 永和店預售優惠方案開跑！</title>
      <meta name="description" content="永和店預售優惠方案開跑" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>永和店預售優惠方案</h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news4pic2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news4pic3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news4pic1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h2>⏰ 早鳥優惠 7/5 7:00pm 準時開跑</h2>
          <h4>大家可以先把APP載好載滿</h4>
          <ul className="icons">
            <li>
              <a
                href="https://apps.apple.com/tw/app/3musclers-%E4%B8%89%E5%81%A5%E5%AE%A2%E5%81%A5%E8%BA%AB%E4%BF%B1%E6%A8%82%E9%83%A8/id1486517436"
                style={{ top: '10px' }}
                className="icon svg"
              >
                <img src={ios} alt="ios" />
              </a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.appworkout.fitbutler.threemusclers&hl=zh_TW"
                style={{ top: '10px' }}
                className="icon svg"
              >
                <img src={google} alt="google" />
              </a>
            </li>
          </ul>
          <p>
            這個暑假，學生福氣拉！ 人魚線、川字肌通通秀出來！ 夏日不秀何時秀🔥🔥
          </p>

          <p>
            <h4>⏰預售時間：7/5-7/31</h4>
            <h4>⏰預計試營運 7/15-7/19</h4>
            <h4>⏰預計開幕 7/21</h4>
            👉🏻早鳥金卡訂閱會員 限量800名 888/月 （沒了就沒了！！（全時段）
            <br />
            👉🏻早鳥銀卡訂閱會員 無限量 588/月（平日 17:00-21:00；假日
            13：00-17:00 除了以上時段都可以進場）
            <br />
            <br />
            以上方案皆可隨時取消，輕鬆零負擔！
            <br />
            ⚠️預售購買的方案生效日於7/21正式營運開始計算，不會多繳錢喔！
          </p>
          <p>--------------------------------------------------------------</p>
          <h2>永和店器材介紹：</h2>
          <h3>🏋🏼‍♀️自由重量</h3>
          <p>
            ELEIKO比賽架跟鑄鐵槓片
            <br />
            四台超抗震硬舉台
            <br />
            美國ROGUE深蹲架7台、腰帶深蹲、臥推架三台、槓很多根，各種特殊槓、
            水牛槓 SSB、足球槓、camber bar、一堆槓片、啞鈴架有四座
            <br />
            <br />
            <h3>🏋🏼‍♀️機械式器材:都是精挑細選</h3>
            <p>（life fitness cybex hammer strength）</p>
            <h3>🏋🏼‍♀️插銷式器材:</h3>
            <p>
              八站式飛鳥、小飛鳥x2、弧形無動力跑步機、下拉划船單機X2、腿外展內收X2、坐式腿後勾、趴式腿後勾、蝴蝶機夾胸後三角
              X2
              、坐式肩推、坐姿腿推、平胸推、坐姿腹肌、二頭、三頭單機側飛挑了三個不同廠牌(LIFE
              CYBEX GYM80)
            </p>
            <h3>🏋🏼‍♀️分動式外掛器材:</h3>
            <p>
              上胸推x2下胸推、正拉、反拉、水平拉、腿推、Hack squat
              、史密斯、臀推
            </p>
            <h3>🏋🏼‍♀️有氧器材:</h3>
            <p>階梯機X2、跑步機X5、ROGUE風扇車X2、飛輪X3</p>
            <h4>還有很多配件跟輔助器材就不詳細列了</h4>
            <h4>
              ⚠️rogue的器材目前因為美國疫情原因還是缺貨中，一有貨我們會盡快分批運回台灣，煩請大家見諒～
            </h4>
          </p>
          <p>--------------------------------------------------------------</p>
          <h2>永和店預售常見問題：</h2>
          <p style={{ color: '#fcce10' }}>Q1:如何購買預售方案成為會員？</p>
          <p>👉🏻下載app直接購買信用卡付款（此優惠方案只能用app購入喔！)</p>
          <p style={{ color: '#fcce10' }}>Q2:預售方方案？</p>
          <p>👉🏻早鳥金卡訂閱會員 限量800名 888/月 （沒了就沒了！！（全時段）</p>
          <p>
            👉🏻單次入場100/次（平日 17:00-21:00；假日 13：00-17:00
            除了以上時段都可以進場）
          </p>
          <p>👉🏻早鳥銀卡訂閱會員 無限量 588/月</p>
          <p style={{ color: '#fcce10' }}>Q3:場館在哪？</p>
          <p>👉🏻永和區民權路80號二樓</p>
          <p style={{ color: '#fcce10' }}>Q4:有沒有停車場</p>
          <p>👉🏻（北城停車場、民權停車場、秀朗國小停車場），走路兩三分鐘會到</p>
          <p style={{ color: '#fcce10' }}>Q5:場館大小？</p>
          <p>👉🏻500坪，可運動範圍板橋店的2.5倍，同時間容納150人不是問題！</p>
          <p style={{ color: '#fcce10' }}>Q6:試營運有完整器材了嗎？</p>
          <p>
            👉🏻ROGUE器材因疫情關係暫時沒貨（槓鈴、槓片的部分），等到有貨我們會立馬購入！！部分ROGUE器材8月會陸續進駐
          </p>
          <p style={{ color: '#fcce10' }}>Q7:是否提供毛巾、鎖頭、吹風機？</p>
          <p>👉🏻毛巾需自備，有提供吹風機、置物櫃有投幣式跟自備鎖頭</p>
          <p style={{ color: '#fcce10' }}>Q8:什麼時候開始試營運？</p>
          <p>👉🏻預計7/15開始 大家可以來參觀，看看器材</p>
          <p>平日：7/15、7/16、7/17（5:00pm-10:00pm)</p>
          <p>假日：7/18、7/19（1:00pm-6:00pm)</p>
          <p style={{ color: '#fcce10' }}>Q9:試營運如何進場？</p>
          <p>👉🏻下載app免費入場喔！！沒有名額限制</p>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2Fvideos%2F955119418365488%2F&show_text=0&width=560"
            width="560"
            height="315"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allowFullScreen="true"
          ></iframe>
          <p>--------------------------------------------------------------</p>
          <h2>器材進場！！！！</h2>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2Fvideos%2F595549588003843%2F&show_text=1&width=560"
            width="560"
            height="474"
				style={{border:'none',overflow:'hidden',backgroundColor:'#fcce10'}}
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
            allowFullScreen="true"
          ></iframe>
          <h4>
            為了維護場館品質2000名金卡會員！所以大家要速速按下訂閱！請隨時關注粉專、ig
            隨時為你搶先公告
          </h4>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
            width="450"
            height="35"
            // style="border:none;overflow:hidden"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News4
